import React from "react";
import { Trans } from "@lingui/macro";
import {
  Box, Grid, Stack, Typography,
} from "@mui/material";
import {
  faBarsProgress,
  faChartPie,
  faChartSimple,
  faFileContract,
  faCubes,
  faLaptop,
  faTrees,
  faChartNetwork,
} from "@fortawesome/pro-light-svg-icons";
import styled from "@emotion/styled";
import _ from "lodash";
import NewLayout from "../../components/NewLayout";
import Hero from "../../components/Hero";
import Container from "../../components/Container";
import ArrowButton from "../../components/ArrowButton";
import LatestUpdates from "../../components/LatestUpdates";
import backgroundImage from "../../images/headers/fund-houses.png";
import dataImage from "../../images/distributors/data-analytics.png";
import techImage from "../../images/distributors/tech.png";
import esgImage from "../../images/distributors/esg.png";
import regulatoryImage from "../../images/distributors/regulatory.png";
import blockchainImage from "../../images/distributors/blockchain.png";
import investmentImage from "../../images/distributors/investment.png";
import InterestedItems from "../../components/InterestedItems";
import QuarterlyFigures from "../../components/QuarterlyFigures";
import { colors } from "../../theme";
import { additionalLinks, headers, restHeaders } from "../../utils/navbar";
import RotatedGridServices from "../../components/RotatedGridServices";

const benefits = () => [
  {
    title: <Trans>Single Access Point, Global Scale</Trans>,
    description: (
      <Trans>
        Over 850 distributors in one place under one single contract. 17 local
        offices providing access and support to the largest distribution
        network.
      </Trans>
    ),
  },
  {
    title: <Trans>Time to Market</Trans>,
    description: (
      <Trans>Join our network and activate your GTM strategy rapidly.</Trans>
    ),
  },
  {
    title: <Trans>Minimize Risk and Expense</Trans>,
    description: (
      <Trans>
        Reduce risk, internal cost and administrative burden with regulatory
        tools, direct onboarding, and registration channels.
      </Trans>
    ),
  },
  {
    title: <Trans>Data with True Potential</Trans>,
    description: (
      <Trans>
        Our data-driven tools detect patterns to help you develop actionable
        insights that can be used to drive business decisions and give you a
        genuine competitive advantage.
      </Trans>
    ),
  },
];

const digitalTools = () => [
  {
    title: <Trans>Allfunds Data Analytics</Trans>,
    description: (
      <Trans>
        Data-driven analytics and business intelligence tools. Advanced data and
        analytics of market flows and assets distribution.
      </Trans>
    ),
    color: colors.tourquesa,
    icon: faChartSimple,
    image: dataImage,
    content: (
      <ul>
        <li>
          <Trans>
            Advanced reporting: Single dashboard with insights of your AUM and
            flows
          </Trans>
        </li>
        <li>
          <Trans>
            Market intelligence: Enhance your global strategy with a Deep-Data
            driven tool
          </Trans>
        </li>
        <li>
          <Trans>
            Commercial optimization with Distribution Optimizer: Optimise your
            sales strategy by identifying potential buyers and building a
            powerful action plan
          </Trans>
        </li>
      </ul>
    ),
    modalPosition: { left: 0 },
    circlePosition: { right: "-1rem", top: "10%" },
    linkPosition: {
      transform: "rotate(-90deg)",
      before: "rotate(45deg) translate(-85%, 50px)",
    },
    gridColumn: "1/2",
    gridRow: "1/2",
  },
  {
    title: <Trans>Allfunds Tech Solutions</Trans>,
    description: (
      <Trans>
        Bespoke solutions and end-to-end solutions for those wishing to unlock
        new distribution channels and streamline their reporting.
      </Trans>
    ),
    color: colors.orangeLight,
    icon: faLaptop,
    image: techImage,
    content: (
      <ul>
        <li>
          <Trans>Open B2C channels to connect with retail clients</Trans>
        </li>
      </ul>
    ),
    modalPosition: { right: "-5rem", top: "-2rem" },
    circlePosition: { left: "-1rem", top: "10%" },
    linkPosition: {
      transform: "rotate(-90deg) translate(125px, -10px)",
      before: "rotate(45deg) translate(85%, -50px)",
    },
    gridColumn: "2/3",
    gridRow: "1/2",
  },
  {
    title: <Trans>Regulatory Solutions</Trans>,
    description: (
      <Trans>
        Regulatory data hub and Mifid Solution Reporting, due diligence portal,
        share class selection, solvency information and corporate actions
        management.
      </Trans>
    ),
    color: colors.blue,
    icon: faFileContract,
    image: regulatoryImage,
    content: (
      <ul>
        <li>
          <Trans>Connect Integrated Dashboard – CID with AML/KYC library</Trans>
        </li>
        <li>
          <Trans>Fund registration and representation services</Trans>
        </li>
        <li>
          <Trans>Regulatory reporting</Trans>
        </li>
      </ul>
    ),
    modalPosition: { left: 0, top: "8rem" },
    circlePosition: { right: "2rem", bottom: "-1rem" },
    linkPosition: {
      transform: "translate(-85px, -78px)",
      before: "rotate(45deg) translate(-85%, 50px)",
    },
    gridColumn: "3/4",
    gridRow: "4/5",
  },
  {
    title: <Trans>ESG Solutions</Trans>,
    description: (
      <Trans>
        An advanced suite of solutions to tackle the ESG challenge and satisfy
        the full cycle of financial institutions’ business needs.
      </Trans>
    ),
    color: colors.green,
    icon: faTrees,
    image: esgImage,
    content: (
      <ul>
        <li>
          <Trans>ESG Analysis</Trans>
        </li>
        <li>
          <Trans>ESG Portfolio Construction</Trans>
        </li>
        <li>
          <Trans>Client ESG Profiling</Trans>
        </li>
        <li>
          <Trans>ESG Investment Proposal Generator</Trans>
        </li>
        <li>
          <Trans>ESG Reporting Generator</Trans>
        </li>
        <li>
          <Trans>ESG Monitoring</Trans>
        </li>
      </ul>
    ),
    modalPosition: { right: "-5rem", top: "15rem" },
    circlePosition: { left: "-1rem", top: "13%" },
    linkPosition: {
      transform: "rotate(-90deg) translate(125px, -10px)",
      before: "rotate(45deg) translate(85%, -50px)",
    },
    gridColumn: "3/4",
    gridRow: "2/3",
  },
  {
    title: <Trans>Allfunds Blockchain</Trans>,
    description: (
      <Trans>
        Proprietary software and network for increased operational efficiency
        and transparency.
      </Trans>
    ),
    color: colors.purple,
    icon: faCubes,
    image: blockchainImage,
    content: (
      <ul>
        <li>
          <Trans>
            Next generation investors will require a unique investment
            experience. Allfunds Blockchain helps its partners to gear up for
            the true digital era and service a new client segment that will
            demand better products with real-time trading activity
          </Trans>
        </li>
      </ul>
    ),
    path: "/distributors/blockchain",
    modalPosition: { left: 0, top: "-20%" },
    circlePosition: { right: "7%", bottom: "-1rem" },
    linkPosition: {
      transform: "translate(-50%, -70px)",
      before: "rotate(225deg) translate(85%, -50px)",
    },
    gridColumn: "2/3",
    gridRow: "3/4",
  },
  {
    title: <Trans>ManCo & Investment Solutions</Trans>,
    description: (
      <Trans>
        Access Fund Hosting and Third-Party Manco services while benefiting from
        all the advantages offered by the Allfunds ecosystem.
      </Trans>
    ),
    color: colors.orange,
    icon: faChartPie,
    image: investmentImage,
    content: (
      <ul>
        <li>
          <Trans>Product development and registration</Trans>
        </li>
        <li>
          <Trans>Risk management and reporting</Trans>
        </li>
        <li>
          <Trans>Distribution services</Trans>
        </li>
        <li>
          <Trans>
            Market Intelligence (ESG, Strategy design, fee setting,
            benchmarking…)
          </Trans>
        </li>
      </ul>
    ),
    path: "/fund-houses/investment",
    modalPosition: { right: "-2rem", top: "15rem" },
    circlePosition: { left: "-1rem", bottom: "7%" },
    linkPosition: {
      transform: "rotate(135deg) translate(-215px, 50px)",
      // transform: "rotate(-180deg) translate(-85px, 70px) scaleX(-1)",
      // before: "rotate(45deg) translate(85%, -50px)",
    },
    gridColumn: "3/4",
    gridRow: "3/4",
  },
  {
    title: <Trans>Alternatives</Trans>,
    color: colors.red,
    icon: faBarsProgress,
    image: blockchainImage,
    content: (
      <ul>
        <li>
          <Trans>
            Onboard your evergreen and/or illiquid funds directly via Allfunds
            distribution agreement
          </Trans>
        </li>
        <li>
          <Trans>
            One single contract to access the entire Allfunds distribution
            network
          </Trans>
        </li>
        <li>
          <Trans>
            Buy-free model applies: make your funds available to distributors at
            zero cost
          </Trans>
        </li>
      </ul>
    ),
    path: "/fund-houses/alternatives",
    modalPosition: { left: 0, top: "-3%" },
    circlePosition: { right: "-1rem", bottom: "30%" },
    linkPosition: {
      transform: "rotate(-180deg) scaleX(-1) translate(-50px, 65px)",
      before: "rotate(45deg) translate(-85%, 50px)",
    },
    gridColumn: "2/3",
    gridRow: "2/3",
    withoutRequestDemo: true,
  },
  {
    title: <Trans>Fund Distribution and Dealing</Trans>,
    description: (
      <Trans>Accurate, secure administrative and operational services.</Trans>
    ),
    color: colors.yellow,
    icon: faChartNetwork,
    content: (
      <ul>
        <li>
          <Trans>Order management, efficient execution</Trans>
        </li>
        <li>
          <Trans>Settlements.</Trans>
        </li>
        <li>
          <Trans>Holdings valuation</Trans>
        </li>
        <li>
          <Trans>
            Asset servicing: stock transfers and corporate actions processing
          </Trans>
        </li>
        <li>
          <Trans>Rebate calculation and reporting</Trans>
        </li>
      </ul>
    ),
    modalPosition: { right: "-5rem", top: "21rem" },
    circlePosition: { left: "-1rem", bottom: "21%" },
    linkPosition: {
      transform: "rotate(-90deg) translate(125px, -10px)",
      before: "rotate(45deg) translate(85%, -50px)",
    },
    gridColumn: "4/5",
    gridRow: "3/4",
  },
];

function FundHousePage() {
  const interestedItems = [
    { ..._.get(additionalLinks(), "onboard_fund_house") },
    { ..._.get(restHeaders(), "distributors") },
    { ..._.get(additionalLinks(), "investment_solutions") },
    { ..._.get(additionalLinks(), "investment_solutions_fh") },
    { ..._.get(headers(), "esg.elements.approach_esg") },
    { ..._.get(additionalLinks(), "blockchain") },
    { ..._.get(headers(), "media") },
    { ..._.get(headers(), "contact") },
  ];

  return (
    <NewLayout apps="fund_houses">
      <Hero
        kind={<Trans>Fund houses</Trans>}
        title={<Trans>Your distribution fast-track</Trans>}
        description={(
          <Trans>
            Access one of the largest distributor networks and benefit from
            leading digital tools to empower your sales approach
          </Trans>
          )}
        backgroundImage={backgroundImage}
        buttonText={<Trans>Contact us</Trans>}
        buttonPath="/contact"
      />
      <Container py={8}>
        <StyledGrid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h3">
              <Trans>
                The go-to platform for all your distribution needs
              </Trans>
            </Typography>
            <ArrowButton
              className="desktop-button"
              title={<Trans>Join now as Fund House</Trans>}
              onClick={() => window.open("https://onboarding.allfunds.com/")}
              isDark
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Stack spacing={2}>
              <Typography>
                <Trans>
                  Under one single agreement, Allfunds provides Fund Houses
                  access to a broad network of institutions as well as to
                  sophisticated digital tools to maximize distribution
                  efforts.
                </Trans>
              </Typography>
              <Typography>
                <Trans>
                  We focus on providing solutions that will simplify your
                  experience and remove any barriers leaving you free to focus
                  on your business.
                </Trans>
              </Typography>
            </Stack>
            <ArrowButton
              className="mobile-button"
              title={<Trans>Join now as Fund House</Trans>}
              onClick={() => window.open("https://onboarding.allfunds.com/")}
              isDark
            />
          </Grid>
        </StyledGrid>
        <Box sx={{ mt: { xs: 5, sm: 10 } }}>
          <QuarterlyFigures kind="fund_houses" />
        </Box>
      </Container>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
          py: { xs: 40, sm: 17 },
        }}
      />
      <Box sx={{ mt: { xs: -80, sm: -31 } }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="white" variant="h3">
                <Trans>Our value proposition for Fund Houses</Trans>
              </Typography>
            </Grid>
            {benefits().map(({ title, description }, index) => (
              <Grid item xs={12} sm={6}>
                <Box
                  padding={2}
                  sx={{
                    backgroundColor: "white",
                    height: "100%",
                    borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.305882)",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <Box display="flex" alignItems="baseline">
                      <StyledNumberIcon>
                        {index + 1}
                        .
                      </StyledNumberIcon>
                    </Box>
                    <Typography ml={1} variant="h3">
                      {title}
                    </Typography>
                  </Box>
                  <Typography>{description}</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box sx={{ position: "relative", overflow: "hidden" }}>
        <Container pt={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="h3">
                <Trans>Our Unique Offering</Trans>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography>
                <Trans>
                  A single hub to manage all your administrative tasks, clear
                  AUM flow display and market intelligence, ESG tools and
                  templates, and of course extensive information on over
                  200,000 funds and ETFs, including alternatives.
                </Trans>
              </Typography>
            </Grid>
          </Grid>
          <RotatedGridServices items={digitalTools()} />
        </Container>
      </Box>
      <Box sx={{ mt: { xs: 3, sm: 5 } }}>
        <LatestUpdates
          title={<Trans>Latest Insights for Fund Houses</Trans>}
          kind="fund_house"
        />
      </Box>
      <Container pb={8}>
        <InterestedItems isSmall items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

const StyledGrid = styled(Grid)(
  ({ theme }) => `
  ${theme.breakpoints.down("sm")} {
    .mobile-button {
      display: block;
      margin-top: 2rem;
    }
    .desktop-button {
      display: none;
    }
  }
  ${theme.breakpoints.up("sm")} {
    .mobile-button {
      display: none;
    }
    .desktop-button {
      display: block;
      margin-top: 1rem;
    }
  }
`,
);

const StyledNumberIcon = styled(Typography)`
  color: ${({ theme }) => `${theme.palette.secondary.main}`};
  font-size: 3rem;
  font-weight: 600;
`;

export default FundHousePage;
